<template>
  <div
    ref="container"
    class="contents"
    :aria-describedby="id"
    data-component-name="VTooltip"
    v-on="listeners"
  >
    <slot />
  </div>
  <VTeleport v-if="(slots.content || props.content) && !props.disabled && tooltipOpen" to="body">
    <div 
      :id="id"
      ref="tooltip"
      role="tooltip"
      class="modern-color-theme font-poppins text-neutral-100 fixed z-[1001] p-2 px-3 bg-neutral-750 shadow rounded-lg text-sm flex gap-3 items-center"
      :class="computedClass"
      :style="position"
    >
      <div>
        <slot name="content" :close="() => tooltipOpen = false">{{ props.content }}</slot>
      </div>
      <div v-if="props.dismissMode === 'manual'">
        <VButton variant="text" size="xs" @click="tooltipOpen = false">
          <VIcon size="sm" color="white" name="Solid/x-mark" />
        </VButton>
      </div>
    </div>
  </VTeleport>
</template>
<script lang="ts" setup>
import VButton from '@component-library/buttons/VButton.vue';
import VIcon from '@component-library/labels/VIcon.vue';
import VTeleport from '@component-library/utilities/VTeleport.vue';
import { useAnimationFramePosition } from '@component-utils/position';
import type { Timeout } from '@component-utils/types';
import { useElementId } from '@component-utils/utils';
import { computed, ref, useTemplateRef } from 'vue';

defineOptions({
  name: 'VTooltip'
})

const props = withDefaults(
  defineProps<{
    /**
     * Tooltip position
     */
    position?: 'bottom left' | 'bottom' | 'bottom right' | 'top left' | 'top' | 'top right' | 'right' | 'left'
    /**
     * Controls how tooltip is hidden
     * 
     * - `auto` - hides tooltip when mouse leaves wrapped element
     * - `custom` - hides tooltip when user clicks integrated close button
     * - `manual` - hides tooltip when exposed close function is called
     */
    dismissMode?: 'custom' | 'auto' | 'manual'
    /**
     * Starts tooltip in visible state
     */
    visible?: boolean
    /**
     * Content of the tooltip. If you need to display more complex content use the `content` slot.
     */
    content?: string
    /**
     * Prevents tooltip from being displayed
     */
    disabled?: boolean
    /**
     * Classes applied to the tooltip div
     */
    tooltipClass?: string
  }>(),
  {
    position: 'bottom left',
    dismissMode: 'auto',
    content: undefined,
    disabled: false,
    visible: false,
    tooltipClass: ''
  }
)

const slots = defineSlots<{
  default(): any
  content(props: { close: () => void }): any
}>()

const id = useElementId(undefined)

const tooltipOpen = ref(props.visible)

const tooltipElement = useTemplateRef('tooltip')
const containerElement = useTemplateRef('container')

const position = useAnimationFramePosition(
  tooltipOpen,
  () => {
    const rect = containerElement.value?.children[0]?.getBoundingClientRect()
    if (!rect) throw new Error('Element is not visible')

    const { width, height } = tooltipElement.value?.getBoundingClientRect() ?? { width: 0, height: 0 }

    switch (props.position) {
      case 'bottom left': {
        return {
          top: `${rect.bottom + 4}px`,
          left: `${rect.left}px`
        }
      }
      case 'bottom': {
        return {
          top: `${rect.bottom + 4}px`,
          left: `${rect.left + rect.width / 2 - width / 2}px`
        }
      }
      case 'bottom right': {
        return {
          top: `${rect.bottom + 4}px`,
          left: `${rect.right - width}px`
        }
      }
      case 'top left': {
        return {
          top: `${rect.top - height - 4}px`,
          left: `${rect.left}px`
        }
      }
      case 'top': {
        return {
          top: `${rect.top - height - 4}px`,
          left: `${rect.left + rect.width / 2 - width / 2}px`
        }
      }
      case 'top right': {
        return {
          top: `${rect.top - height - 4}px`,
          left: `${rect.right - width}px`
        }
      }
      case 'right': {
        return {
          top: `${rect.top + rect.height / 2 - height / 2}px`,
          left: `${rect.right + 4}px`
        }
      }
      case 'left': {
        return {
          top: `${rect.top + rect.height / 2 - height / 2}px`,
          left: `${rect.left - width - 4}px`
        }
      }
    }
  }
)

// Timer used to delay opening
const timer = ref<Timeout>()

const toggleLater = (shouldOpen: boolean) => {
  if (shouldOpen) {
    if (timer.value) return

    timer.value = setTimeout(() => {
      timer.value = undefined

      tooltipOpen.value = true
    }, 600)
  } else {
    clearTimeout(timer.value)

    timer.value = undefined

    tooltipOpen.value = false
  }
}

const openListeners = {
  mouseenter: () => toggleLater(true),
  focusin: () => toggleLater(true)
}

const closeListeners = {
  mouseleave: () => toggleLater(false),
  focusout: () => toggleLater(false)
}

const listeners = computed(() => {
  const closeListenersForDismissMode = props.dismissMode === 'auto' ? closeListeners : {}

  if (props.visible) {
    return closeListenersForDismissMode
  } else {
    return {
      ...openListeners,
      ...closeListenersForDismissMode
    }
  }
})

const computedClass = computed(() => {
  const isBottom = props.position === 'bottom'
  const isTop = props.position === 'top'
  const isLeft = props.position === 'left'
  const isRight = props.position === 'right'

  const isBottomRight = props.position === 'bottom right'
  const isBottomLeft = props.position === 'bottom left'
  const isTopRight = props.position === 'top right'
  const isTopLeft = props.position === 'top left'

  return {
    'after:content-[""] after:absolute after:border-4 after:border-transparent': true,
    'after:border-b-neutral-750 after:-top-2': isBottom || isBottomLeft || isBottomRight,
    'after:border-t-neutral-750 after:-bottom-2': isTop || isTopLeft || isTopRight,
    'after:border-r-neutral-750 after:-left-2': isRight,
    'after:border-l-neutral-750 after:-right-2': isLeft,
    'after:left-1/2 after:-translate-x-1/2': isTop || isBottom,
    'after:top-1/2 after:-translate-y-1/2': isLeft || isRight,
    'after:left-3': isBottomLeft || isTopLeft,
    'after:right-3': isBottomRight || isTopRight,
    [props.tooltipClass]: true
  }
})
</script>
