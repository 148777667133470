import { watch, type Ref } from "vue"
import { useManualTracking } from "./reactivity"

export function useAnimationFramePosition <T> (isOpenRef: Ref<boolean>, positionCalculationMethod: () => T | never) {
  const { computedRef: position, recompute: recomputePosition } = useManualTracking(positionCalculationMethod)

  watch(isOpenRef, (value) => {
    if (value) {
      const animationFrameCallback = () => {
        recomputePosition()
  
        if (isOpenRef.value) {
          window.requestAnimationFrame(animationFrameCallback)
        }
      }
  
      window.requestAnimationFrame(animationFrameCallback)
    }
  })

  return position
}