<template>
  <div :data-teleported-content-id="id" class="contents" />
  <Teleport v-if="mounted" :to="props.to" :disabled="props.disabled">
    <div :id="id" data-teleported-content class="contents">
      <slot />
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { useElementId } from '@component-utils/utils';
import { onMounted, ref } from 'vue';

defineOptions({
  name: 'VTeleport'
})

const props = defineProps<{
  to: string
  disabled?: boolean
}>()

const id = useElementId(undefined)
const mounted = ref(false)

onMounted(() => {
  mounted.value = true
})
</script>